

































































































































































import Base from '@/mixins/Base.vue';
import { IService, IServiceResponse } from '@/interfaces/service';
import { ITag } from '@/interfaces/tag';

const component = Base.extend({
    data() {
        return {

            active: false,
            service: {} as IService,
        };
    },
    mounted() {
        this.active = true;
    },
    methods: {
        save(): void {
            this.post<IServiceResponse>('services', {
                name: this.service.name,
                code: this.service.code,
                source_id: this.service.source ? this.service.source.id : null,
                category_id: this.service.category ? this.service.category.id : null,
                tags: this.service.tags.map((t: ITag) => t.id),
            }).then(({ data }) => {
                this.$router.push({ name: 'services.edit', params: { id: data.data.id.toString() } } as any);
            });
        },
    },
});

export default component;
