var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('portal',{attrs:{"to":"modal"}},[_c('b-modal',{attrs:{"active":_vm.active,"can-cancel":false}},[_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passes = ref.passes;
var valid = ref.valid;
return [_c('form',{staticClass:"modal-card",on:{"submit":function($event){$event.preventDefault();return passes(_vm.save)}}},[_c('header',{staticClass:"modal-card-head"},[_c('p',{staticClass:"modal-card-title"},[_vm._v(" Create a new service ")])]),_c('section',{staticClass:"modal-card-body"},[_c('ValidationProvider',{attrs:{"immediate":"","rules":"required","name":"name","tag":"b-field"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Name","type":{'is-danger': errors[0]},"message":errors ? errors[0] : null}},[_c('b-input',{attrs:{"disabled":_vm.isLoading},model:{value:(_vm.service.name),callback:function ($$v) {_vm.$set(_vm.service, "name", $$v)},expression:"service.name"}})],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"immediate":"","rules":"required","name":"code","tag":"b-field"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Code","type":{'is-danger': errors[0]},"message":errors ? errors[0] : null}},[_c('b-input',{attrs:{"disabled":_vm.isLoading},model:{value:(_vm.service.code),callback:function ($$v) {_vm.$set(_vm.service, "code", $$v)},expression:"service.code"}})],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"immediate":"","rules":"required","name":"category_id","tag":"b-field"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Category","type":{'is-danger': errors[0]},"message":errors ? errors[0] : null}},[_c('sp-service-category',{attrs:{"disabled":_vm.isLoading,"append-to-body":""},model:{value:(_vm.service.category),callback:function ($$v) {_vm.$set(_vm.service, "category", $$v)},expression:"service.category"}})],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"immediate":"","rules":"required","name":"building_type_id","tag":"b-field"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Building type","type":{'is-danger': errors[0]},"message":errors ? errors[0] : null}},[_c('sp-building-type',{attrs:{"disabled":_vm.isLoading,"append-to-body":""},model:{value:(_vm.service.building_type_id),callback:function ($$v) {_vm.$set(_vm.service, "building_type_id", $$v)},expression:"service.building_type_id"}})],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"immediate":"","rules":"required","name":"source_id","tag":"b-field"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Source","type":{'is-danger': errors[0]},"message":errors ? errors[0] : null}},[_c('sp-source',{attrs:{"append-to-body":"","disabled":_vm.isLoading,"client-id":_vm.currentUser.current_client_id},model:{value:(_vm.service.source),callback:function ($$v) {_vm.$set(_vm.service, "source", $$v)},expression:"service.source"}})],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"immediate":"","rules":"required","name":"tags","tag":"b-field"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Tags","type":{'is-danger': errors[0]},"message":errors ? errors[0] : null}},[_c('sp-tags',{attrs:{"disabled":_vm.isLoading,"append-to-body":""},model:{value:(_vm.service.tags),callback:function ($$v) {_vm.$set(_vm.service, "tags", $$v)},expression:"service.tags"}})],1)]}}],null,true)})],1),_c('footer',{staticClass:"modal-card-foot"},[_c('sp-link',{attrs:{"tag":"b-button","disabled":_vm.isLoading,"to":{name: 'services.list'},"label":_vm.$t('close'),"icon-left":"times"}}),_c('b-button',{attrs:{"disabled":! valid || _vm.isLoading,"label":_vm.$t('create'),"icon-left":"save","type":"is-primary","native-type":"submit"}})],1)])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }